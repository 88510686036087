import { ORDER_ITEM_BATCH_FRAGMENT } from '../fragments';
import { gql } from "@apollo/client";
import { ModelDefinitionType } from '../castAttributesFromDefinition';
import { OrderItemBatchType } from '../../react/types';
import useGraphqlModel from './useGraphqlModel';

export const orderItemBatchDefinition: ModelDefinitionType = {
  supplierId: 'Integer',
  status: 'String',
  comment: 'String',
};

const GET_ORDER_ITEM_BATCH = gql`
  query orderItemBatch($id: ID!) {
    orderItemBatch(id: $id) {
      ...OrderItemBatchInfo
    }
  }
  ${ORDER_ITEM_BATCH_FRAGMENT}
`

const useOrderItemBatch = useGraphqlModel<OrderItemBatchType>('orderItemBatch', {
  flashMessages: true,
  fragment: ORDER_ITEM_BATCH_FRAGMENT,
  queries: {
    fetch: GET_ORDER_ITEM_BATCH,
  },
  definition: orderItemBatchDefinition
});

export default useOrderItemBatch;