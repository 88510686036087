import { ID } from '../react/types';
import { gql } from '@apollo/client';

export const USER_FRAGMENT = gql`
  fragment UserInfo on User {
    id
    email
    lastRequestAt
  }
`;

export const SUPPLIER_CONTACT_FRAGMENT = gql`
  fragment SupplierContactInfo on SupplierContact {
    id
    supplierId
    contact
  }
`;

export const SUPPLIER_FRAGMENT = gql`
  fragment SupplierInfo on Supplier {
    id
    title
    token
    firstTokenAccessAt
    comment
    sendType
    allowImport
    displayPurchasePrices
    displayShippingPrices
    supplierContacts {
      ...SupplierContactInfo
    }
  }
  ${SUPPLIER_CONTACT_FRAGMENT}
`;

export const ORDER_FRAGMENT = gql`
  fragment OrderInfo on Order {
    id
    origin
    supplierOrderRef
    supplier {
      ...SupplierInfo
    }
  }
  ${SUPPLIER_FRAGMENT}
`;

export const ORDER_ITEM_FRAGMENT = gql`
  fragment OrderItemInfo on OrderItem {
    id
    createdAt
    title
    compiereOrderId
    compiereLineNumber
    compiereProductRef
    status
    sentToCompiereAt
    carrier
    trackingNumber
    quantity
    orderItemBatchId
    reportDate
    order {
      ...OrderInfo
    }
  }
  ${ORDER_FRAGMENT}
`;

export const ORDER_ITEM_BATCH_FRAGMENT = gql`
  fragment OrderItemBatchInfo on OrderItemBatch {
    id
    supplierId
    status
    comment
  }
`;

export const EXCELSIOR_IMPORT_FRAGMENT = gql`
  fragment ExcelsiorImportInfo on ExcelsiorImport {
    id
    file {
      filename
      url
    }
    state
    importType
    supplierIds
    createdLines
    updatedLines
    createdAt
    linesFailed 
  }
`;

export const PAGINATION_FRAGMENT = gql`
  fragment Pagination on Pagination {
    page
    perPage
    total
  }
`;

export const VERSION_FRAGMENT = gql`
  fragment VersionInfo on Version {
      event
      createdAt
      user {
        id
        email
      }
      changes
    }
`;
