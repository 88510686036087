// @ts-nocheck
import forIn from 'lodash/forIn';
import isObject from 'lodash/isObject';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import { decamelize } from 'humps';

import moment from 'moment';

const toFloat = (v: number) => parseFloat(v.toString().replace(/\,/g, '.'));

const defaultOptions = {
  skipUndefinedKeys: true,
};

type OptionsType = {
  skipUndefinedKeys?: boolean;
};

type PlacesType = {
  type: 'Places';
  options?: {
    formattedAddress?: string;
    lat?: string;
    lng?: string;
    city?: string;
  }
}

export type ModelDefinitionType<RecordType = unknown, Keys extends keyof RecordType = keyof RecordType> = {
  [K in Keys]:
    | 'Password'
    | 'Float'
    | 'Integer'
    | 'Other'
    | 'Nested'
    | 'Datetime'
    | 'Date'
    | 'String'
    | 'ID'
    | 'Files'
    | 'File'
    | 'Boolean'
    | 'Array'
    | PlacesType
    | ModelDefinitionType;
};

export type AttributesType = {
  [k: string]: any;
};

const castValue = (type, value) => {
  switch (type) {
    case 'Float':
      return toFloat(value);
    case 'Integer':
      return parseInt(value, 10);
    case 'String':
      return (typeof value === 'string' ? value : value.toString());
    case 'Date':
      return (moment.isMoment(value) ? value.format('YYYY-MM-DD') : value);
    case 'Datetime':
      return (moment.isMoment(value) ? value.format() : value);
    case 'Password':
    case 'Files':
    case 'File':
      if (value) return value;
      return;
    default:
      return value;
  }
}

const reverseCastValue = (type, value) => {
  switch (type) {
    case 'Float':
      return toFloat(value);
    case 'Integer':
      return parseInt(value, 10);
    case 'String':
      return (typeof value === 'string' ? value : value.toString());
    case 'Datetime':
    case 'Date':
      return moment.isMoment(value) ? value : moment(value);
    default:
      return value;
  }
}

export const reverseCastFromDefinition = (model: ModelDefinitionType, attributes: AttributesType) => {
  const castedAttributes: any = {};
  forIn(model, (value, key) => {
    if (Array.isArray(value) && !isNil(attributes[key])) {
      castedAttributes[key] = attributes[key].map(v => reverseCastValue(value[0], v))
    } else if (!isNil(attributes[key])) {
      castedAttributes[key] = reverseCastValue(value, attributes[key]);
    }
  })
  return castedAttributes;
} 

function castAttributesFromModel(
  model: ModelDefinitionType,
  attributes: AttributesType,
  options: OptionsType = defaultOptions,
) {
  const formattedModel: any = {};
  forIn(model, (value, key) => {
    if (isObject(value)) {
      if (isArray(value) && !isNil(attributes[key])) {
        formattedModel[key] = attributes[key].map(v => castValue(value[0], v));
      }
      else {
        if (!isNil(attributes[key])) {
          const attributeValue = attributes[key];
          if (attributeValue)
            formattedModel[`${key}Attributes`] = isArray(attributeValue)
              ? attributeValue.map((v) => castAttributesFromModel(value, v))
              : castAttributesFromModel(value, attributeValue);
        }
      }
    } else if (!isNil(attributes[key])) {
      switch (value) {
        case 'Float':
          formattedModel[key] = toFloat(attributes[key]);
          break;
        case 'Integer':
          formattedModel[key] = parseInt(attributes[key], 10);
          break;
        case 'String':
          formattedModel[key] =
            typeof attributes[key] === 'string'
              ? attributes[key]
              : attributes[key].toString();
          break;
        case 'Date':
          formattedModel[key] = moment.isMoment(attributes[key]) ?
              attributes[key].format('YYYY-MM-DD')
              : attributes[key];
          break;
        case 'Datetime':
          formattedModel[key] = moment.isMoment(attributes[key]) ?
            attributes[key].format()
            : attributes[key];
          break;
        case 'Password':
        case 'Files':
        case 'File':
          if (attributes[key]) formattedModel[key] = attributes[key];
          break;
        case 'Nested':
          if (attributes[key])
            formattedModel[`${key}Attributes`] = isArray(attributes[key])
              ? attributes[key].map((v) => omit(v, ['__typename']))
              : omit(attributes[key], ['__typename']);
          break;
        default:
          formattedModel[key] = attributes[key];
          break;
      }
    } else if (value !== 'Password' && value !== 'Nested' && value !== "ID") {
      if (!options.skipUndefinedKeys || attributes.hasOwnProperty(key))
        formattedModel[key] = null;
    }
  });
  return formattedModel;
};

const appendObjectFieldToFormData = (
  formData: FormData,
  prefix: string,
  object: any,
) => {
  if (isArray(object)) {
    object.forEach((v, index) => {
      formData = appendObjectFieldToFormData(
        formData,
        `${prefix}[${isObject(v) && !isArray(v) ? index : ''}]`,
        v,
      );
    });
  } else if (isObject(object)) {
    forIn(object, (fieldValue, fieldName) => {
      formData = appendObjectFieldToFormData(
        formData,
        `${prefix}[${decamelize(fieldName)}]`,
        fieldValue,
      );
    });
  } else formData.append(`${prefix}`, object);
  return formData;
};

export default castAttributesFromModel;
