import { useIntl } from "react-intl"

const useI18n = () => {
  const intl = useIntl();
  const t = (key, args = {}) => intl.formatMessage({ id: key }, args);
  const tAttribute = (modelName, attributeName) => intl.formatMessage({ id: `activerecord.attributes.${modelName}.${attributeName}`});
  return {
    t,
    translate: t,
    tAttribute,
  }
}

export default useI18n;
